import React from "react";
import * as styles from "./Communicate.module.scss";
import UpperSection from "../../Shared/UpperSection";
import { StaticImage } from "gatsby-plugin-image";
import { useScreenSize } from "../../../hooks";

export const Communicate = () => {
  const { isMobile, isTablet } = useScreenSize();
  return (
    <UpperSection
      sectionName="Text Messaging"
      textTitle="Auto shop text messaging made easy"
      description="Connect with your customers with auto repair shop texting."
      clsLeft="textMessagingDistance"
      clsRight="textMessagingImgDistance"
      formClass="UpperSectionForm"
    >
      <div className={styles.imagesWrap}>
        {isTablet ? (
          <>
            <StaticImage
              src="../../../assets/images/TextMessaging/feature-tablet.png"
              alt="feature"
              className={styles.feature}
              loading="eager"
              quality={100}
            />
            <StaticImage
              src="../../../assets/images/TextMessaging/replies-tablet.png"
              alt="replies"
              className={styles.replies}
              loading="eager"
              quality={100}
            />
            <StaticImage
              src="../../../assets/images/TextMessaging/ring.png"
              alt="feather icon"
              className={styles.ring}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
          </>
        ) : isMobile ? (
          <StaticImage
            src="../../../assets/images/TextMessaging/hero-mobile.png"
            alt="feature"
            className={styles.heroMobile}
            loading="eager"
            quality={100}
          />
        ) : (
          <>
            <StaticImage
              src="../../../assets/images/TextMessaging/feature.png"
              alt="feature"
              className={styles.feature}
              loading="eager"
              quality={100}
            />
            <StaticImage
              src="../../../assets/images/TextMessaging/replies.png"
              alt="replies"
              className={styles.replies}
              loading="eager"
              quality={100}
            />
          </>
        )}

        {!isMobile && (
          <>
            <StaticImage
              src="../../../assets/images/TextMessaging/feather.png"
              alt="feather icon"
              className={styles.feather}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
            <StaticImage
              src="../../../assets/images/TextMessaging/message.png"
              alt="message icon"
              className={styles.message}
              loading="eager"
              quality={100}
              style={{
                position: "absolute",
              }}
            />
          </>
        )}
      </div>
    </UpperSection>
  );
};
